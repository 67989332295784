<template>
	<div id="content">
		<Header v-bind:infos="infos" />
		<main>
			<!--Section caractéristiques générales-->
			<div class="container caracteristiquesGeneralesProjet">
				<div class="row">
					<!--Le commanditaire du projet-->
					<div
						class="commanditaireProjet col-sm-12 col-md-12 col-lg-4 col-xl-4"
					>
						<div>
							<h2>COMMANDITAIRE</h2>
							<p>Projet personnel</p>
						</div>
					</div>

					<!--La date de réalisation du projet-->
					<div
						class="dateProjet offset-lg-1 offset-xl-1 col-sm-12 col-md-12 col-lg-3 col-xl-3"
					>
						<div>
							<h2>ANNÉES</h2>
							<p>2022</p>
						</div>
					</div>

					<!--Les services apportés pour ce projet-->
					<div class="servicesProjet col-sm-12 col-md-12 col-lg-4 col-xl-4">
						<div>
							<h2>SERVICES</h2>
							<p>
								Développement front-end, webdesign, algorithme
							</p>
						</div>
					</div>
				</div>
			</div>

			<!--Section présentation du projet-->
			<div class="container">
				<div class="row">
					<p class="contenuProjet col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Au cours de mon année de césure, j'ai commencé à apprendre la langue
						coréenne en autodidacte car j'ai toujours souhaité apprendre une
						nouvelle langue étrangère qui ne soit pas une langue occidentale
						afin de découvrir de nouvelles cultures, étendre mon esprit et
						apporter un regard différent sur certaines choses.<br /><br />
						Lors de mon apprentissage, je suis passé par les nombres coréens où
						il existe deux systèmes :<br />
						- Les nombres purement coréens allant de 1 à 99, utilisés
						généralement pour compter des objets, les heures ou pour donner son
						âge<br />
						- Les nombres sino-coréens allant de 0 à l'infini, utilisés pour
						compter l'argent, les minutes et les numéros de téléphone<br />
						Afin de me familiariser avec l'apprentissage de ces nombres, il
						m'est venu l'idée de créer un générateur de nombres aléatoires
						coréens des deux types.<br /><br />
						Pour cela, j'ai créé un système utilisant les règles coréennes pour
						former ces nombres automatiquement jusqu'à 999 999 (il est possible
						d'aller plus loin). J'en ai profité pour faire de même avec la
						langue française et anglaise avec les règles qui leur sont propres.
						Par la suite, j'ai fais en sorte que l'utilisateur puisse choisir la
						langue voulue, un intervalle avec une valeur minimale et maximale et
						qu'il puisse générer ensuite le nombre aléatoire. Celui-ci s'affiche
						en nombre avec un point d'interrogation, en cliquant sur ce point
						d'interrogation, cela affiche l'écriture de ce nombre en lettres
						dans la langue choisie. Concernant les technologies utilisées, j'ai
						simplement utilisé du HTML | CSS | JS avec le préprocesseur Sass,
						pratique pour créer des variables pouvant être modifiées rapidement
						et utiliser leur principe de mixins.
					</p>

					<div
						class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
						style="margin-top: -3vh;"
					>
						<a
							href="https://antoninwinterstein.com/Random_number_to_letter_generator/"
							class="boutonVoirSite"
							>LIEN VERS LE PROJET</a
						>
					</div>

					<!--Image 1-->

					<img
						src="../assets/randomnumbertolettergenerator/instructions.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Aperçu de la modal indiquant les instructions pour utiliser le générateur de nombre en lettre aléatoire"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Aperçu de la modal indiquant les instructions pour utiliser le
						générateur de nombre en lettre aléatoire
					</p>

					<!--Image 2-->

					<img
						src="../assets/randomnumbertolettergenerator/nombre_en_lettre.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Aperçu de la page avec un nombre généré aléatoirement traduit en
						sino-coréen"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Aperçu de la page avec un nombre généré aléatoirement traduit en
						sino-coréen
					</p>
				</div>
			</div>

			<div class="container">
				<!--Bouton voir les autres projets-->
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
						<router-link to="projets" class="boutonVoirTousLesProjets"
							>VOIR LES AUTRES PROJETS</router-link
						>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
	metaInfo: function() {
		return {
			title: "Antonin Winterstein | Random Number to Letter Generator",
			meta: [
				{
					name: "description",
					content:
						"Découvrez le projet de création d'un générateur de nombre en lettre aléatoire réalisé par Antonin Winterstein !",
				},
			],
		};
	},
	components: {
		Header,
	},
	data() {
		return {
			infos: {
				titrePage: "RANDOM NUMBER TO LETTER GENERATOR - SITE WEB",
				urlImage: require("../assets/projets/fond_random_number_to_letter_generator.jpg"),
			},
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style>
@import "../styles/pageProjet.css";
</style>
